<template>
  <div id="settings">
    <Header :items="items" path="settings" :customHeaderTitle="customHeaderTitle" />
    <router-view />
  </div>
</template>

<script>
import Header from '@/components/Header'

export default {
  components: { Header },
  data() {
    return {
      menuOpened: false,
      items: [
        // {
        //   label: this.$t('sidebar.profile'),
        //   route: 'Profile'
        // },
        // {
        //   label: this.$t('sidebar.fellowTravelers'),
        //   route: 'FellowTravelers'
        // },
        {
          label: this.$t('sidebar.language'),
          route: 'Language'
        },
        {
          label: this.$t('sidebar.notifications'),
          route: 'Notifications'
        },
      ],
      customHeaderTitle: null
    }
  },
  methods: {
    updateCustomHeaderTitle() {
      let interval = setInterval(() => {
        if(!this.$refs.view) return
        clearInterval(interval)
        this.customHeaderTitle = this.$refs.view.customHeaderTitle
      }, 100)
    }
  },
  updated() {
    this.updateCustomHeaderTitle()
  }
}
</script>

<style lang="scss">
  #settings {
    padding-top: 100px;
  }
</style>